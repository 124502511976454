import React from 'react';
import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from './components/Homepage/Homepage';
import CourseLandingPage from './components/Courses/CourseNavigator/CourseLandingPage';
import CPLandingPage from './components/Courses/CoursePage/CPLandingPage';
import Header from './components/Header/Header';
import { ResumeBuilderIframe } from './components/ResumeBuilder/ResumeBuilderIframe';
import Footer from './components/Footer/Footer';
import CourseListing from './components/Courses/CoursesAndVideosListing/CourseListing';
import VideosListing from './components/Courses/CoursesAndVideosListing/VideosListing';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';

import { DBConfig } from './utils/config';
import { initDB } from 'react-indexed-db';
import CourseProvider from './contexts/CourseContext';
import HomePageContextProvider from './contexts/HomepageContext';
import ConnectWithUs from './components/CommonPages/ConnectWithUs';
import AboutUs from './components/CommonPages/AboutUs';
import Terms from './components/CommonPages/Terms';
import Privacy from './components/CommonPages/Privacy';
import Login from './components/LoginAndSignup/Login'

initDB(DBConfig);

function App() {
  return (
    <div className="App">
      <Router>
        <>
          <Header />
          <Switch>
            <Route exact path="/"  render={props=><HomePageContextProvider><Homepage {...props} /></HomePageContextProvider>} />
            <Route exact path="/courses" component={CourseListing} />
            <Route path="/courses/:course_seo_url" render={(props) => <CourseProvider> <CPLandingPage {...props} /> </CourseProvider>} />
            <Route exact path="/course-material/:course_seo_url" render={(props) => <CourseProvider> <CourseLandingPage {...props} /></CourseProvider>} />
            <Route path="/resume-builder" component={ResumeBuilderIframe} />
            <Route exact path="/videos" component={VideosListing} />
            <Route exact path="/contact-us" component={ConnectWithUs} />
            <Route exact path="/about-us" render={props=><HomePageContextProvider><AboutUs {...props} /></HomePageContextProvider>}/>
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/login" render={()=><Login display={true}></Login>} />
            <Route exact path="/privacy-policy" component={Privacy} />
           

            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
        </>
      </Router>
    </div>
  );
}

export default App;
