import React, { useState } from 'react'
import styles from './FAQs.module.css'
import { useEffect } from 'react'
import { Row, Col, Tabs, Container, Tab, Accordion, Card } from 'react-bootstrap'
import { getDataFromApi } from '../../utils/restAPIhelper'

const FAQs = ({ theme = "dark" }) => {
    const [faqs, setFaqs] = useState([])
    useEffect(() => {
        getDataFromApi("faqs").then(data => {
            setFaqs(data)
        })
    }, [])


        return (<>
            <div  className={theme==="light"?styles.containerLight:styles.containerDark}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col sm={11}>
                            <p className={`primary-color bold uppercase ${styles.title}`}>HELP & SUPPORT</p>
                            <h2>Frequently asked questions</h2>
                            <Tabs className={`${styles.Tabs}`}>
                                {
                                    faqs.map(tab => {
                                        console.log(tab)
                                        if (tab.active)
                                        return (
                                            <Tab eventKey={tab._id} title={tab.name} tabClassName={theme==="light"?"light-tabs":""}>
                                                <Accordion>
                                                    {renderFaqs(tab.faqs)}
                                                </Accordion>
                                            </Tab>
                                        )
                                        else
                                        return null
                                    })
                                }
                            </Tabs>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>)
}

const renderFaqs = (faqs) => {
    return faqs.map(faq => {
        return (<>
            <Accordion.Toggle as={Card.Header} eventKey={faq._id}>
                <p className={styles.faqQuestion}>{faq.question}</p>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={faq._id}>
                <p className={`${styles.faqAnswer}`} dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
            </Accordion.Collapse>
        </>
        )
    })
}

export default FAQs