import React, {useState, useContext} from 'react'
import styles from './CPPreview.module.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import ImageBanner from '../../ImageBanner/ImageBanner'

import { useIndexedDB } from 'react-indexed-db';
import { DBOBJECT_USERS } from '../../../utils/config';
import { CourseContext } from '../../../contexts/CourseContext'

const CPPreview = (props) =>{

  const db = useIndexedDB(DBOBJECT_USERS)

  const history = useHistory()
  const context = useContext(CourseContext)


  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');

  function onClick(){
    db.getAll().then(users=>{
        if (users.length > 0)
        history.push(`/course-material/${context.course.seo_url}`)
        else
        setShowDetailsPopup(true);
    },err=>{
      setShowDetailsPopup(true);
    })
      
  }

  function onEmailChange(event){
      setEmail(event.target.value);
  }

  function oncontactNoChange(event){
      setContactNo(event.target.value);
  }

  function goToCourseAfterDataValidation(){
    
      //TODO - add validations here
      props.goToCourseAfterDataValidation(email, contactNo);
  }

    return (
        <div>
        <Container>
                <Row className="justify-content-md-center">
                <Col sm={11}>
                        <div className={styles.coursePreviewImage}>
                        {(() => {
                          if(!showDetailsPopup){
                            return (
                                <ImageBanner image={props.courseImage} style={{marginTop:0}} />
                                  
                              )
                            }
                          else{
                            return (
                                <div>
                                    <Container className={styles.modalVerticalCentre}>
                                        <Row className="justify-content-md-center">
                                            <p className={`offset-color bold uppercase ${styles.title}`}>Leave Details To Continue</p>
                                        </Row>
                                        <Row className="justify-content-md-center">
                                            <Col sm={6} className={`text-center ${styles.columnCorrection}`}>
                                            <input onChange={onEmailChange} id="email" type="email" className={styles.inputDetails} placeholder="Email ID" />
                                            </Col>
                                            <Col sm={6} className={`text-center ${styles.columnCorrection}`}>
                                            <input onChange={oncontactNoChange} id="contact_no" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className={styles.inputDetails} placeholder="Phone no" required />
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-md-center">
                                            <Button onClick={goToCourseAfterDataValidation} className={styles.center} variant="outline-btn-dark" >Start Free Course</Button>
                                        </Row>
                                    </Container>
                                </div>
                            )
                          }
                        })()}
                        </div>
                        </Col>
                </Row>
                {(() => {
                  if(!showDetailsPopup){
                    return ( <Row sm={{ offset: 1 }}>
                        <Button onClick={onClick} className={styles.startFreeCourseButton} variant="outline-btn-dark" >Start Free Course</Button>
                      </Row>
                      )
                    }
                })()}

        </Container>
    </div>
    )
}

export default CPPreview;
