import React, {useState, useEffect, useContext } from 'react';
import NewsLetter from '../../NewsLetter/NewsLetter'
import CPPreview from './CPPreview'
import CPDescription from './CPDescription'
import CPTopics from './CPTopics'
import FreeCourses from '../CourseCommonComponents/FreeCourses'
import BlankGreyArea from '../CourseCommonComponents/BlankGreyArea'

import { getDataFromApi, sendDataToApi } from '../../../utils/restAPIhelper';
import { useIndexedDB } from 'react-indexed-db';
import { DBOBJECT_USERS } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import { CourseContext } from '../../../contexts/CourseContext';
import { useNotification } from '../../../hooks/notification-hook';
import Notification from '../../Notifications/Notification';
import { GUEST_REGISTRATON_SUCCESS } from '../../../utils/successMessages';

const CPLandingPage = (props) =>{

  const db = useIndexedDB(DBOBJECT_USERS);
  const history = useHistory();
  const courseContext = useContext(CourseContext)
  const courseData = courseContext.course
  const {notificationProps,showNotification,hideNotifcation} = useNotification();

  function goToCourseAfterDataValidation(email, contact_no){
      if(email && contact_no){
             sendDataToApi("registerguestusers",{email:email,phone_number:contact_no}).then(guestuser=>{
              db.add({email:email,phone_number:contact_no,isRegistered:false}).then(user=>{
                showNotification(GUEST_REGISTRATON_SUCCESS)
                setTimeout(()=>{
                  history.push(`/course-material/${props.match.params.course_seo_url}`)
                },1000)
              })
             }).catch(error=>{
              if (error.status === 400)
              showNotification(error.errorResponse,true)
          })
            
      
      }
  }


  useEffect(() => {
    if (!props.course)
      getDataFromApi(`courses/${props.match.params.course_seo_url}`).then(data=>{
       courseContext.setCourse(data)
        window.scrollTo(0, 0)
      })
  }, [props.match.params.course_seo_url])


    return (
        <div>
          
          {(() => {
                  return ( <div>    
                        <Notification {...notificationProps}/>
                        <CPPreview goToCourseAfterDataValidation={goToCourseAfterDataValidation} courseImage={courseData.thumbnail}/>
                        <CPDescription title={courseData.title} about={courseData.about} sample_video={courseData.sample_video} data={courseData}/>
                        <FreeCourses />
                        {/* <BlankGreyArea /> */}
                        <NewsLetter/>
                        
                  </div>
                )
          })()}
        </div>
    );
}

export default CPLandingPage;
