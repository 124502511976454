import React from 'react'
import styles from './CPDescriptionDetails.module.css'
import { Container, Row, Col } from 'react-bootstrap'

const CPDescriptionDetails = (props) =>{

  

    return (
        <div>
        <Container>
                  <Row className="justify-content-md-center">
                      <Col sm={11}>
                        <p className={`primary-color bold uppercase ${styles.title}`}>About This Course</p>
                        <h2 className={`secondary-color`}>{props.title}</h2>
                        <div className="margin-top-20" dangerouslySetInnerHTML={{__html:`${props.about}`}}></div>
                      </Col>
                  </Row>
              </Container>
    </div>
    )
}

export default CPDescriptionDetails;
