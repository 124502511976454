import React from 'react'
import styles from './ResumeBuilderIframe.module.css'

export const ResumeBuilderIframe = () => {

    const access_token = localStorage.getItem("user_access_token");

    if (!!access_token)
    return (
       <iframe src={`https://navigator.vahura.com/builder?token=${access_token}`} title="resume-builder" className={styles.frame}/>
    )

    return (<div style={{paddingTop:'120px',textAlign:"center",height:'calc(100vh - 200px)'}}>You Need to Login to Access Resume Builder</div>)
}
