import React, { useState } from 'react'
import styles from './NewsLetter.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useInput } from '../../hooks/input-hook';
import { sendDataToApi } from '../../utils/restAPIhelper';
import { useError } from '../../hooks/error-meesage-hook';
import { NEWSLETTER_SUBSCRIBED } from '../../utils/successMessages';

const NewsLetter = (props) =>{

    const { value: subscriberEmail, bind: bindemail, reset: resetsubscriberEmail } = useInput('');
    const [error,setError] = useState({isError:false,code:0,validator:"email"})
    const [successMessage,setSuccessMessage] = useState()
    const errorMessage = useError(error)
    
    const _handleKeyDown=(e)=> {
        if (e.key === 'Enter') {
          sendDataToApi("newsletter",{email:subscriberEmail}).then(data=>{
            setError({isError:false})
            setSuccessMessage(NEWSLETTER_SUBSCRIBED)
            resetsubscriberEmail();
          }).catch(error=>{
              console.log(error.status)
              setError({isError:true,code:error.status,validator:"email"})
          })
        }
      }

    return (
        <div className={styles.newsLetterContainer}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={3}>
                        <p className="primary-color bold uppercase">Events. Webinars. Meet Ups</p>
                        <h1>Stay Updated!</h1>
                    </Col>
                    <Col sm={7}>
                        <input type="email" className="input" placeholder="Leave email ID" {...bindemail} onKeyDown={(e)=>_handleKeyDown(e)}/>
                        <p className={error.isError? "error" : "success"}>{error.isError ? errorMessage : successMessage}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NewsLetter