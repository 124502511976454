import React, { useEffect, useState, useContext } from 'react'
import styles from './Homepage.module.css'
import Header from '../Header/Header'
import Intro from './Intro/Intro'
import ResumeBuilderIntro from './ResumeBuilderIntro/ResumeBuilderIntro'
import CoursesIntro from './CoursesIntro/CoursesIntro'
import NewsLetter from '../NewsLetter/NewsLetter'
import Aboutus from './AboutUs/Aboutus'
import ConnectWithUs from './ConnectWithUs/ConnectWithUs'
import Testimonials from './Testimonials/Testimonials'
import Footer from '../Footer/Footer'
import FAQs from '../FAQs/FAQs'
import ContactForm from '../ContactForm/ContactForm'
import BlankGreyArea from '../Courses/CourseCommonComponents/BlankGreyArea'
import { getDataFromApi } from '../../utils/restAPIhelper'
import { HomePageContext } from '../../contexts/HomepageContext'


const Homepage = () => {

    const [intro, setIntro] = useState({})
    const [coursesIntro, setCourseIntro] = useState({})
    const [aboutus, setAboutus] = useState({})
    const [connect, setConnectWithUs] = useState({})
    const homepageContext = useContext(HomePageContext)

    useEffect(() => {
        getDataFromApi("homepage").then(data => {
            setIntro(data.intro)
            setCourseIntro(data.courses)
            setAboutus(data.about_us)
            setConnectWithUs(data.connect)
            homepageContext.setHomePageData(data)
            localStorage.setItem("homepageBannerImage",data.intro.image)
        })
    }, [])


        return (
            <>
            <Intro data={intro} />
            <ResumeBuilderIntro />
            <CoursesIntro data={coursesIntro} />
            <NewsLetter />
            <Aboutus data={aboutus}/>
            <ConnectWithUs data={connect}/>
            {/* <Testimonials /> */}
            <BlankGreyArea theme="light" /> {/*Comment the BlankArea when Testimonials is uncommented*/}
            <FAQs theme="dark"/>
            <ContactForm theme="dark"/>

            </>
        )
    }


    export default Homepage