import React from 'react'

const NextArrow = (props)=> {
    const {className, style, onClick} = props
    return (
        <div
            className={className}
            style={{...style, display: 'block'}}
            onClick={onClick}
        >
           <p className="secondary-color">
           {'>'}
           </p>
        </div>
    );
}

export default NextArrow