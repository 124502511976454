import React, { useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import styles from './ResumeBuilderIntro.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlayCircle, faCross, faTimes} from '@fortawesome/free-solid-svg-icons'
import YouTube from 'react-youtube';
import { useHistory } from 'react-router-dom'


var youtubePlayerInstance = {}
var playerRef = {}

const ResumeBuilderIntro = (props) =>{
    playerRef= useRef(null)

    const WatchVideo = (e) =>{
        playerRef.current.hidden = false
        youtubePlayerInstance.playVideo();
    }
const history = useHistory()
   
const gotoResumeBuilder = () =>{
    history.push('/resume-builder')
}
    return (
        <div className={styles.hirationBox}>
        <Container>
            <Row>
                <Col sm={{ span: 8, offset: 1 }}>
                    <p className="primary-color bold uppercase">Resume Builder</p>
                    <h1>Ready-to-use <br />
                        Resumes</h1>
                    <p className="small-text">Powered By</p>

                </Col>
            </Row>
            <Row>
                <Col sm={{ span: "auto", offset: 1 }}>
                    <img src={require('../../../assets/hiration-logo.png')} alt="resume-builder-logo" />
                </Col>
                <Col className="margin-auto">
                    <p className="margin-auto cursor-pointer" onClick={e=>WatchVideo(e)}>Watch Video <FontAwesomeIcon icon={faPlayCircle}/></p>
                    
                </Col>
            </Row>
            <Row>
                <Col sm={{ offset: 1 }}>
                    <Button variant="outline-dark" onClick={e=>gotoResumeBuilder()}>Let's go</Button>
                </Col>
            </Row>
        </Container>
        {renderVideoPlayer()}
    </div>

    )

   
}

const CloseVideo = () =>{
    youtubePlayerInstance.pauseVideo();
    playerRef.current.hidden = true
}

function _onReady(event){
    youtubePlayerInstance = event.target
    if (playerRef)
    {
        playerRef.current.hidden = true
    }

}

const renderVideoPlayer = () =>{

    return (<>
    <div className={styles.greyout} ref={playerRef}>
    <FontAwesomeIcon icon={faTimes} className={styles.closeButton} onClick={()=>CloseVideo()}/>
    <YouTube
        className={styles.youtubePlayer}   
        videoId="04n_iHK94Pc"
        onReady={_onReady}
      />
    </div>
    </>)
}

export default ResumeBuilderIntro