import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CourseMaterialViewPane from './CourseMaterialViewPane'
import CourseMaterialList from './CourseMaterialList'



const CoursePreviewPane = (props) =>{

  const [courseRecord, setCourseRecord] = useState();

  const onClick = (courseRecord) =>{
      setCourseRecord(courseRecord);
  }

  useEffect(() => {
      if (!!props.data)
      setCourseRecord(props.data[0])
  }, [props.data])

  if (!!props.data && courseRecord)
    return (
        <div>
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row style={{ marginRight: 0}}>
            <Col sm={9} style={{ marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
                <CourseMaterialViewPane courseRecord={courseRecord}/>
            </Col>
            <Col sm={3} style={{ marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
                <CourseMaterialList onClick={onClick} dataSet={props.data}/>
            </Col>
        </Row>
    </Container>
    </div>
    )

    return <div className="primary-color bold uppercase" style={{padding:'140px 0 0 25%'}}>Loading...</div>
}

export default CoursePreviewPane;
