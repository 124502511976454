import React, { useState, useRef } from 'react'
import styles from './Aboutus.module.css'
import { Container, Row,Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlayCircle, faTimes} from '@fortawesome/free-solid-svg-icons'
import YouTube from 'react-youtube'
import { getVideoIdFromUrl } from '../../../utils/utils'

var youtubePlayerInstance = {}
const Aboutus = ({data}) =>{

    const [showVideo,setVideoVisibility] = useState(false)

    const WatchVideo = () =>{
        if (youtubePlayerInstance)
        {
            youtubePlayerInstance.playVideo();
        }
        setVideoVisibility(true);
        document.body.style.overflow = 'hidden'
    }

    const youtubeWrapper = useRef(null)
   
    return (<div className={styles.Section}>
        <Container>
            <Row className="justify-content-md-center">
                <Col sm={7}>
                <p className={`primary-color bold uppercase ${styles.title}`}>About us</p>
                <h2 className="secondary-color">{data.title}</h2>
                </Col>
                <Col sm={2} className="dotted-border-right">
                </Col>
                <Col sm={2}>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col sm={3}>
                    <div className={styles.thumbnail}>
                        <img src={require('../../../assets/logo.png')}/>
                    </div>
                    <p className="secondary-color small-text cursor-pointer" onClick={e=>{WatchVideo()}}>Watch now  <FontAwesomeIcon icon={faPlayCircle}/></p>
                </Col>
                <Col sm={6} className="dotted-border-right"> 
                <p className={`secondary-color ${styles.aboutusText}`}>{data.description}</p>
                </Col>
               
                <Col sm={2} > 
                <div className={styles.sideStats}>
                    <p className={`primary-color bold ${styles.sideStatValue}`}>{data.sideStatValue}</p>
                    <p className={`secondary-color ${styles.sideStatLabel}`}>{data.sideStatLabel}</p>
                    </div>
                </Col>
            </Row>
           
            <div className="full-width-video" ref={youtubeWrapper} style={showVideo?{display:'block'}:{display:'none'}}>
            <FontAwesomeIcon icon={faTimes} className="closeButton" onClick={()=>CloseVideo()} />
            <YouTube 
            videoId={getVideoIdFromUrl(data.videoUrl)}
            onReady={_onReady}
            opts={{
                width:'890',
                height:'500'
            }}
            />
            </div>
        </Container>

    </div>)

function _onReady(event){
   youtubePlayerInstance = event.target
}

function CloseVideo(){
    youtubePlayerInstance.pauseVideo();
     setVideoVisibility(false)
     document.body.style.overflow = 'unset'
}


}

export default Aboutus