import React, { createContext } from 'react'
import { useState } from 'react';

export const CourseContext = createContext();
const CourseProvider = (props)=> {

    const [course,setCourse] = useState({})

    return (
        <CourseContext.Provider
        value={{
            course : course,
            setCourse : course => setCourse(course)
        }}>
           {props.children} 
        </CourseContext.Provider>
    )
}

export default CourseProvider
