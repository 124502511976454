import React from 'react'
import styles from './CourseMaterial.module.css'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import CourseMaterialRecord from './CourseMaterialRecord'

const CourseMaterialList = (props) =>{
    return (
        <div className={styles.sidePane}>
        <Container fluid={true} >
            <Row className={styles.headingSidePane} >
              <p  className={`primary-color bold uppercase ${styles.title}`}>Course Material</p>
            </Row>
            <Row >
              <ListGroup defaultActiveKey={0}  variant="flush">
                  {(() => {
                    return props.dataSet.map((data, index)=>{
                      return (
                          <ListGroup.Item key={index} onClick={() => props.onClick(data)}  action variant="light">
                            <CourseMaterialRecord record={data} index={index+1}/>
                          </ListGroup.Item>
                      )
                    });
                  })()}
              </ListGroup>
            </Row>
    </Container>
    </div>
    )
}

export default CourseMaterialList;
