import React, { createContext } from 'react'
import { useState } from 'react';

export const HomePageContext = createContext();
const HomePageContextProvider = (props)=> {

    const [homePageData,setHomePageData] = useState({})

    return (
        <HomePageContext.Provider
        value={{
            homePageData : homePageData,
            setHomePageData : homePageData => setHomePageData(homePageData)
        }}>
           {props.children} 
        </HomePageContext.Provider>
    )
}

export default HomePageContextProvider
