import React, {useState, useEffect} from 'react'
import styles from './YoutubeWrapper.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import YouTube from 'react-youtube';

const states = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    VIDEOCUED: 5
}


const YoutubeWrapper = (props) =>{
  const [playerRef, setPlayerRef] = useState(null);
  let userInfo = localStorage.getItem('vahuraGuestInfo');

  function getVideoKey(playerRef){
    console.log("playerRef:::",playerRef)
      return userInfo+"-"+props.videoId;
  }

  function getResumePlayFromTime(playerRef){
      let resumePlayFromTime = Math.floor(localStorage.getItem(getVideoKey(playerRef)));
      return resumePlayFromTime;
  }

  function setResumePlayFromTime(playerRef){
      let resumePlayFromTimeLs = getResumePlayFromTime(playerRef);
      console.log(props.videoId+"-"+"resumePlayFromTimeLs:::",resumePlayFromTimeLs);
      let resumePlayFromTime = Math.floor(playerRef.getCurrentTime());
      console.log(props.videoId+"-"+"resumePlayFromTime:::", getResumePlayFromTime(playerRef));

      localStorage.setItem(getVideoKey(playerRef), resumePlayFromTime);
      return resumePlayFromTime;
  }

  function _onReady(event) {
      event.target.pauseVideo();
      setPlayerRef(event.target);
      // let resumePlayFromTime = getResumePlayFromTime(event.target);
      // event.target.seekTo(resumePlayFromTime);
  }

  useEffect(()=>{
    if(playerRef)
    {
      let resumePlayFromTime = getResumePlayFromTime(playerRef);
      playerRef.seekTo(resumePlayFromTime);
    }

  },[props])

  function _onPlayerStateChange(event){
    setResumePlayFromTime(playerRef);
  }

  return (
      <div style={{ ...props.youtubePosition }} >
      <Container fluid={true} >
          <Row>
            <div className={styles.previewPane}>
            <YouTube
                className={styles.youtubePlayer}
                videoId={props.videoId}
                opts={props.opts}
                onReady={_onReady}
                
              />
            </div>
          </Row>
      </Container>
  </div>
  )
}

export default YoutubeWrapper;
