import React from 'react'
import styles from './ImageBanner.module.css'


const ImageBanner = (props) => {
    return (
               <div className={`${styles.introImage} ${styles.container}`}
                            style={{ backgroundImage: `url(${props.image})`,...props.style }}>
                            <p className="primary-color bold uppercase">{props.title}</p>
                            <h1>{props.subtitle}</h1>
                        </div>
    )
}

export default ImageBanner
