import React from 'react'
import styles from './CPTopics.module.css'
import { Container, Row, Col } from 'react-bootstrap'

const CPTopics = (props) =>{
    return (
        <div className={styles.pageTopics}>
        <Container>
                  <Row className="justify-content-md-center">
                      <Col sm={11}>
                        <p className={`primary-color bold uppercase ${styles.title}`}>Topics Discussed</p>
                        <div className={styles.descriptionMargin} dangerouslySetInnerHTML={{__html:props.data}}></div>
                      </Col>
                  </Row>
        </Container>
    </div>
    )
}

export default CPTopics;
