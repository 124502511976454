import React from 'react'
import styles from './BlankGreyArea.module.css'

const BlankGreyArea = (props) =>{

    return (<div className={props.theme==="light"?styles.whiteArea:styles.greyArea}>

    </div>)


}

export default BlankGreyArea
