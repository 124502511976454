import React, { useState,useEffect } from 'react'
import styles from './Menu.module.css'
import { Link, useHistory } from 'react-router-dom'
import Login from '../LoginAndSignup/Login'
import Signup from '../LoginAndSignup/Signup'
import { useIndexedDB } from 'react-indexed-db'
import { DBOBJECT_USERS } from '../../utils/config'


const Menu = () => {
    const [isMenuOpen,setMenuOpen] = useState(false)
    const [showLoginModal,setShowLoginModal] = useState(false)
    const [showSignupModal,setShowSignupModal] = useState(false)
    const history = useHistory();

    const db = useIndexedDB(DBOBJECT_USERS)

    useEffect(() => {
        const unlisten = history.listen(()=>{
            setMenuOpen(false)
            document.body.style.overflow = 'unset'
        })
        return () => {
            unlisten()
        };
    }, [isMenuOpen])

    useEffect(() => {
        let currentPage = history.location.pathname
        let isUserLoggedIn = !!localStorage.getItem("user_access_token")
        if(currentPage === "/resume-builder" && !isUserLoggedIn )
        {
            setShowLoginModal(true)
        } 
        window.scrollTo(0,0);
    }, [history.location.pathname])

    const toggleMenu=()=>{
        setMenuOpen(!isMenuOpen)
    }

    const LoginMenuItemCliked=(e)=>{
        e.preventDefault();
        setShowLoginModal(true)
    }

    const LoginModalClosed =()=>{
        setShowLoginModal(false)
    }

    const LogoutCliked = ()=>{
        db.clear();
        localStorage.removeItem("user_access_token")
    }

    const onSignUpClicked = () =>{
        setShowLoginModal(false)
        setShowSignupModal(true)
    }

    const onLoginClicked = () =>{
        setShowLoginModal(true)
        setShowSignupModal(false)
    }

    const SignupModalClosed =()=>{
        setShowSignupModal(false)
    }



    const renderLoginorLogoutButton = () =>{
        let user = localStorage.getItem("user_access_token")
        if (!!user)
        return <a href="" onClick={e=>{LogoutCliked(e)}}>Logout</a>
        else
        return <a href="" onClick={e=>{LoginMenuItemCliked(e)}}>Login</a>
    }
    
    return (<>
        <div className={styles.menuToggleButton} onClick={e=>{toggleMenu()}}>
                <p className={`${styles.menu} top`}></p>
                <p className={`${styles.menu} mid`}></p>
                <p className={`${styles.menu} bottom`}></p>
            </div>
        <div className={`${styles.MenuContainer} ${isMenuOpen?styles.true:styles.false}`}>
                <Link to="/courses">Courses</Link>
                <Link to="/resume-builder">Resume Builder</Link>
                <Link to="/videos">Videos</Link>
                {/* <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).quora:""} target="_blank">Vahura's Quora Page</a> */}
                {/* <Link>Job Site</Link> */}
                <Link to="/contact-us">Contact</Link>
                {renderLoginorLogoutButton()}
        </div>
        <Login display={showLoginModal} onClose={LoginModalClosed} onSignUpClicked={onSignUpClicked}/>
        <Signup display={showSignupModal} onClose={SignupModalClosed} onLoginClicked={onLoginClicked} />
        </>
    )
}



export default Menu
