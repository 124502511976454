import React from 'react'
import styles from './CourseDescription.module.css'
import { Container, Row,Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlayCircle} from '@fortawesome/free-solid-svg-icons'

const CourseDescription = (props) =>{

    return (<div>
        <Container>
            <Row className="justify-content-md-center">
                <Col sm={11}>
                  <p className={`primary-color bold uppercase ${styles.title}`}>Description</p>
                  <div className={styles.descriptionMargin} dangerouslySetInnerHTML={{__html:props.description}}>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>)

}

export default CourseDescription
