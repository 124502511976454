// export const baseUrl = '//localhost:9000/web';
// export const baseUrl = '//navigator.vahura.com/api/web';
export const baseUrl = 'https://navigator.vahura.com/api/web';

export const DBOBJECT_USERS = "Users"
export const DBOBJECT_COURSE_STATUS = "course_status"

export const DBConfig = {
    name: "VahuraNavigator",
    version: 1,
    objectStoresMeta: [
      {
        store: DBOBJECT_USERS,
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'phone_number', keypath: 'phone_number', options: { unique: false } },
          { name: 'email', keypath: 'email', options: { unique: true } },
          { name: 'isRegistered' },
        ]
      },
      {
        store : DBOBJECT_COURSE_STATUS,
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'url', keypath: 'url', options: { unique: true } },
          { name: 'status' },
        ]
      }
    ]
  };