import React from 'react'
// import PDFViewer from 'pdf-viewer-reactjs'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import styles from './PdfViewer.module.css'

const PdfViewer = (props) =>{
    return (
      <Container fluid={true} >
            <Row className={styles.canvasCss}>
                <iframe src={props.url} width="100%" height="582.5px" style={{marginTop: "96px",border:'inset 0'}}>
                </iframe>
            </Row>
      </Container>
    )
}

export default PdfViewer

// const PdfViewer = (props) =>{
//     return (
//           <Container fluid={true} >
//                 <Row>
//                     <PDFViewer
//                         document={{
//                             url: props.url,
//                         }}
//                         loader={
//                           <Spinner animation="border" role="status">
//                               <span className="sr-only">Loading...</span>
//                           </Spinner>
//                         }
//                         canvasCss={styles.customCanvas}
//                     />
//                 </Row>
//           </Container>
//      )
// }
//
// export default PdfViewer
