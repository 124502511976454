import React from 'react'
import styles from './Intro.module.css'
import { Container, Row, Col } from 'react-bootstrap'

const Intro = ({ data }) => {
    return (
        <div className={`primary-background ${styles.intro}`}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={11}>
                        <div className={styles.introImage}
                            style={{ backgroundImage: `url(${data.image})` }}>
                            <p className="primary-color bold uppercase">{data.title}</p>
                            <h1>{data.subtitle}</h1>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.introStats}>
                    <Col md={{ span: 6, offset: 1 }}>
                        <Container><Row>  {renderIntroStats(data.stats ? data.stats : [])}</Row></Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const renderIntroStats = (stats) => {
    return stats.map(stat => {
        return (
            <Col className="text-align-center">
                <>
                    <p className={`${styles.statValue} bold`}>{stat.value}</p>
                    <p className={styles.statLabel}>{stat.label}</p>
                </>
            </Col>
        )
    })
}

export default Intro;