import React,{useContext,useState, useEffect} from 'react'
import ImageBanner from '../ImageBanner/ImageBanner'
import { Container, Row, Col } from 'react-bootstrap'
import NewsLetter from '../NewsLetter/NewsLetter'
import { HomePageContext } from '../../contexts/HomepageContext'
import { getDataFromApi } from '../../utils/restAPIhelper'

function AboutUs() {

    const homepageContext = useContext(HomePageContext)
    const [aboutUs,setAboutus] = useState(homepageContext.homePageData.about_us)
    
    useEffect(()=>{
        if (!aboutUs)
            getDataFromApi("homepage").then(data => {
                setAboutus(data.about_us)
            })
       
    },[])


    return (<>
        <Container style={{ paddingTop: '100px' }}>
            <Row className="justify-content-md-center">
                <Col sm={11}>
                    <ImageBanner image={localStorage.getItem("homepageBannerImage")} title="ABOUT" subtitle="Dedicated to shape your career, the right way." />
                    <Container >
                        <Row style={{ color: "#3c3c3b", margin: "50px 0" }}>
                          <div dangerouslySetInnerHTML={{__html:aboutUs?aboutUs.long_description:"<p>Loading...</p>"}}>

                          </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
        <NewsLetter />
    </>


    )
}

export default AboutUs
