import { SOMETHING_WENT_WRONG, DUPLICATE_EMAIL_ERROR, INVALID_EMAIL } from "../utils/errorMessages"

export const useError = (error) => {
    if (error.isError) {
        switch (error.code) {
            case 409:
                if (error.validator === "email")
                    return DUPLICATE_EMAIL_ERROR
            case 400:
                if (error.validator === "email")
                    return INVALID_EMAIL
            default:
                return SOMETHING_WENT_WRONG

        }
    }

    else
        return ""
}