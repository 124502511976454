import React, { useState, useEffect,useRef } from 'react'
import styles from './CoursesAndVideosListing.module.css'
import ImageBanner from '../../ImageBanner/ImageBanner'
import { Container, Row, Col } from 'react-bootstrap'
import NewsLetter from '../../NewsLetter/NewsLetter'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import YouTube from 'react-youtube'

import { getDataFromApi } from '../../../utils/restAPIhelper'
import { getVideoIdFromUrl } from '../../../utils/utils'

var youtubePlayerInstance = null
const VideosListing = () => {

    const [showVideo, setVideoVisibility] = useState(false)
    const [videoUrl,setVideoUrl] = useState();

    const WatchVideo = () => {
        console.log("in youtube watchVideo:::",youtubePlayerInstance)
        if (!!youtubePlayerInstance) {
            youtubePlayerInstance.playVideo();
        }
        setVideoVisibility(true);
        document.body.style.overflow = 'hidden'
    }

    const youtubeWrapper = useRef(null)

    const [videos, setVideos] = useState([])

    useEffect(() => {
        getDataFromApi("videos").then(videos => {
            setVideos(videos)
        })
    }, [])

    const renderListing = () => {
        return videos.map(video => {
            return (
                <Col md={3}>
                    <div className={styles.videoItem} onClick={e=>{setVideoUrl(video.video_url);WatchVideo()}}>
                        <img src={video.picture ? video.picture : require('../../../assets/default-navigator.jpg')} />
                        <p className="secondary-color bold"> {video.title}</p>
                    </div>
                </Col>
            )
        })
    }

    return (
        <>
            <Container className={styles.container}>
                <Row className="justify-content-md-center">
                    <Col sm={11}>
                        <ImageBanner image="https://navigator.vahura.com/api/uploads/5e2661a1779e037690fb78c1-1588066047123.jpg" title="VIDEOS" subtitle="Guidance to take your career that extra mile!" />
                        <p className={`primary-color bold uppercase ${styles.listingTitle}`}>ALL VIDEOS</p>
                        <Container >
                            <Row>
                                {renderListing()}
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <div className="full-width-video" ref={youtubeWrapper} style={showVideo ? { display: 'block' } : { display: 'none' }}>
                    <FontAwesomeIcon icon={faTimes} className="closeButton" onClick={() => CloseVideo()} />
                    <YouTube
                        videoId={getVideoIdFromUrl(videoUrl)}
                        onReady={_onReady}
                        opts={{
                            width: '890',
                            height: '500',
                            playerVars: {
                                autoplay: 0,
                                rel: 0
                              }
                        }}
                    />
                </div>
            </Container>
            <NewsLetter />
        </>
    )


    function _onReady(event) {
        youtubePlayerInstance = event.target
    }

    function CloseVideo() {
        if (!!youtubePlayerInstance)
        youtubePlayerInstance.pauseVideo();
        setVideoVisibility(false)
        document.body.style.overflow = 'unset'
    }
}

export default VideosListing
