import React from 'react'
import styles from './CourseDescription.module.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlayCircle} from '@fortawesome/free-solid-svg-icons'
import YoutubeWrapper from '../../YoutubeWrapper/YoutubeWrapper';
import { useHistory } from 'react-router-dom'

const youtubeOpts = {
      height: '282',
      width: '500',
      playerVars: {
        autoplay: 0,
        rel: 0
      }
};

const youtubePosition = {
  marginBottom: 70,
  paddingLeft: 4,
  paddingRight: 0
}


const HirationResumeBuilder = (props) =>{

  const history = useHistory()
   
const gotoResumeBuilder = () =>{
    history.push('/resume-builder')
}

  return (<div>
        <Container>
            <Row className="justify-content-md-center">
                <Col sm={7}>
                  <p className={`primary-color bold uppercase ${styles.title}`}>Resume Services</p>
                  <h2 className={`secondary-color`}>Try our Online Resume Review!</h2>
                </Col>
                <Col sm={2} >
                </Col>
                <Col sm={2}>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                  <Col sm={6}>
                      <div className={styles.descriptionMargin}>
                        <YoutubeWrapper
                          opts={youtubeOpts}
                          videoId="KZw077OgeIA"
                          youtubePosition={youtubePosition}
                        />
                      </div>

                    </Col>
                    <Col sm={5}>
                      <Container>
                        <Row>
                          <div className={styles.resumeBullets}>
                            Resume Services:
                            <ul>
                              <li>  Targeted resume templates for a variety of legal jobs</li>
                              <li>  20+ modern resume design templates</li>
                              <li>  Live resume editings</li>
                              <li>  AI powered resume review</li>
                              <li>  Optimized cover letter creator</li>
                            </ul>
                          </div>
                        </Row>
                        <Row sm={{ offset: 1 }}>
                                <Button className={styles.tryNowButton} onClick={e=>gotoResumeBuilder()} variant="outline-light" >Let's Go</Button>
                        </Row>
                      </Container>
                    </Col>
            </Row>
        </Container>
    </div>)


}

export default HirationResumeBuilder
