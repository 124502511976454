import React,{useState, useEffect} from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';

const CourseDoneSwitch = (props) =>{
    const [isChecked, setIsChecked] = useState(null);

    function onClick(){
      setIsChecked(isChecked=>!isChecked);
    }

    useEffect(()=>{
      if(isChecked != null)
      props.statusChanged(isChecked)
    },[isChecked])

    if (isChecked || props.status === "Completed") 
    return (
      <CheckBoxRoundedIcon onClick={onClick} style={{ fill: '#85AC5A' }}/>
  );

    return (
      <CheckBoxRoundedIcon onClick={onClick} style={{ fill: '#ccc' }}/>
    );
};

export default CourseDoneSwitch;
