import React, { Component, useEffect, useContext } from 'react';
import CoursePreviewPane from './CoursePreviewPane'
import CourseDescription from './CourseDescription'
import FreeCourses from '../CourseCommonComponents/FreeCourses'
import HirationResumeBuilder from './HirationResumeBuilder'
// import Header from '../Header/Header'
// import Footer from '../Footer/Footer'
import NewsLetter from '../../NewsLetter/NewsLetter'
import BlankGreyArea from '../CourseCommonComponents/BlankGreyArea'
// import { Container } from 'react-bootstrap'

// import source from '../CoursePage/CourseData'
import { CourseContext } from '../../../contexts/CourseContext';
import { getDataFromApi } from '../../../utils/restAPIhelper';
import { useHistory } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import { DBOBJECT_USERS } from '../../../utils/config';

// var courseData = source.courseData;
// var coursesList = source.coursesList;

const CourseLandingPage = (props) => {

  const courseContext = useContext(CourseContext)
  let courseData = courseContext.course;

  const db = useIndexedDB(DBOBJECT_USERS)
  const history = useHistory()

  useEffect(() => {
    db.getAll().then(users => {
      if (users.length > 0)
        return
      else
        history.push('/courses')
    }, err => {
      history.push('/courses')
    })

  })

  useEffect(() => {
    if (!courseData.data) {
      getDataFromApi(`courses/${props.match.params.course_seo_url}`).then(data => {
        courseContext.setCourse(data)
        window.scrollTo(0, 0)
      })
    }
  }, [props.match.params.course_seo_url])


  return (
    <div>
      <CoursePreviewPane data={courseData.data} />
      <CourseDescription description={courseData.description} />
      <FreeCourses />
      <HirationResumeBuilder />
      {/* <BlankGreyArea /> */}
      <NewsLetter />

    </div>
  );
}

export default CourseLandingPage;
