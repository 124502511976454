import React from 'react'
import styles from './CPDescription.module.css'
import CPDescriptionDetails from './CPDescriptionDetails'
import CPDescriptionVideoDetails from './CPDescriptionVideoDetails'
import { Container, Row, Col } from 'react-bootstrap'
import { getVideoIdFromUrl } from '../../../utils/utils'
import CPTopics from './CPTopics'


const CPDescription = (props) =>{

    return (
        <div>
        <Container>
            <Row className="justify-content-md-center">
              <Col sm={6}>
                <CPDescriptionDetails title={props.title} about={props.about} />
                <CPTopics data={props.data.topics_discussed}/>
              </Col>
              <Col className={styles.columnCorrection} sm={6}>
                <CPDescriptionVideoDetails data={props.data} videoId={getVideoIdFromUrl(props.sample_video)}/>
              </Col>
            </Row>
        </Container>
    </div>
    )
}

export default CPDescription;
