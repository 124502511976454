import React from 'react'
import styles from './NotFoundPage.module.css'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

function NotFoundPage() {
    return (<>

        <div className={styles.notfoundContainer}>
            <h2>Oops! Looks like the page you are looking for does not exist</h2>
            <p>Perhaps, You would like to some of our most visited pages</p>
            <Container>
                <Row>
                    <Col>
                        <Link to="/courses"> <div className={styles.pagelinks}>
                            <img src={require('../../assets/logo.png')} alt="Navigator Logo" />
                        </div>
                        <p className={`${styles.linkText} offset-color`}>Courses</p>
                        </Link>
                    </Col>
                    <Col>
                    <Link to="/"> <div className={styles.pagelinks}>
                            <img src={require('../../assets/logo.png')} alt="Navigator Logo" />
                        </div>
                        <p className={`${styles.linkText} offset-color`}>Homepage</p>
                        </Link>
                    </Col>
                    <Col>
                    <Link to="/videos"> <div className={styles.pagelinks} >
                            <img src={require('../../assets/logo.png')} alt="Navigator Logo" />
                        </div>
                        <p className={`${styles.linkText} offset-color`}>Videos</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
    )
}

export default NotFoundPage
