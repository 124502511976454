import React, { useState, useEffect } from 'react'
import styles from './CoursesIntro.module.css'
import Slider from 'react-slick'
import NextArrow from '../../Carousel/NextArrow'
import PrevArrow from '../../Carousel/PrevArrow'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { getDataFromApi } from '../../../utils/restAPIhelper'

const CoursesIntro = ({ data }) => {


    const [courses, setCourses] = useState([])
    const history = useHistory();

    useEffect(() => {
        getDataFromApi("courses").then(courses => {
            courses.sort((a, b) => {
                if (!!a.display_order && !!b.display_order)
                    return a.display_order - b.display_order
                return -1 })
            setCourses(courses)
        })
    }, [])

    const goToCourse = (event, url) => {
        history.push(`/courses/${url}`)
    }

    const renderCourses = (courses) => {
        var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };

        return (<Slider {...settings}>
            {courses.map(course => {
                return (
                    <div className={styles.courseItem} onClick={(e) => goToCourse(e, course.seo_url)}>
                        <img src={course.thumbnail ? course.thumbnail : require('../../../assets/default-navigator.jpg')} />
                        <p>{course.title}</p>
                    </div>
                )
            })}
        </Slider>
        )

    }

    return (
        <div className={styles.coursesIntro}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={{ span: 7 }} className={styles.courseIntroText}>
                        <div className={`${styles.doubleBorderContainer} border-left-right`}>
                            <h1 className="bold secondary-color border-left-right">{data.title}</h1>
                        </div>
                    </Col>
                    <Col sm={2} className="dotted-border-right">
                    </Col>
                    <Col sm={2}>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col sm={{ span: 7 }} >
                        <p className={`bold primary-color uppercase ${styles.freeCourseLabel}`}>Free courses</p>
                        <h2 className="secondary-color">{data.courseTitle}</h2>

                    </Col>
                    <Col sm={2} className="dotted-border-right">
                    </Col>
                    <Col sm={2}>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center`}>
                    <Col sm={{ span: 8 }} className={`${styles.carousel}`}>
                        {renderCourses(courses)}
                    </Col>
                    <Col sm={1} >
                        <div className={`dotted-border-right ${styles.dottedBorder}`}>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <div className={styles.sideStats}>
                            <p className={`primary-color bold ${styles.sideStatValue}`}>{data.sideStatValue}</p>
                            <p className={`secondary-color ${styles.sideStatLabel}`}>{data.sideStatLabel}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}


export default CoursesIntro