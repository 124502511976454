import React,{useEffect} from 'react'
import styles from './Footer.module.css'
import { Container,Row,Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuora,faFacebook,faLinkedin,faInstagram,faTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'
import { getDataFromApi } from '../../utils/restAPIhelper';
import { Link } from 'react-router-dom';



const Footer =(props) =>{

    useEffect(()=>{
        if (!sessionStorage.getItem('social_links'))
        getDataFromApi("social_links").then(data=>{
            sessionStorage.setItem('social_links',JSON.stringify(data[0]))
        })
    },[])

    return (
        <div className={`${styles.FooterContainer} primary-background`}>
        <Container>
            <Row className="justify-content-md-center">
                <Col sm={2}>
                <img className={styles.footerLogo} src={require('../../assets/vahura-navigator-logo.png')} alt="Navigator Logo" />
                </Col>
                <Col sm={{span:2,offset:1}} className={styles.SiteMap}>
                    <Link to="/about-us" className="uppercase">About us</Link>
                    <Link to="/terms" className="uppercase">{"TERMS & CONDITIONS"}</Link>
                    <Link to="/privacy-policy" className="uppercase">PRIVACY POLICY</Link>
                    <Link to="/contact-us" className="uppercase">Contact us</Link>
                </Col>
                <Col sm={{span:2,offset:1}}>
                    <p className="uppercase">Follow us</p>
                    <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).youtube:""} target="_blank"><FontAwesomeIcon icon={faYoutube} title="Youtube"/></a>
                    <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).facebook:""} target="_blank"><FontAwesomeIcon icon={faFacebook} title="Facebook"/></a>
                    <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).instagram:""} target="_blank"><FontAwesomeIcon icon={faInstagram} title="Instagram"/></a>
                    <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).linkedin:""} target="_blank"><FontAwesomeIcon icon={faLinkedin} title="LinkedIn"/></a>
                    <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).twitter:""} target="_blank"><FontAwesomeIcon icon={faTwitter} title="Twitter"/></a>
                    <a href={sessionStorage.getItem('social_links')?JSON.parse(sessionStorage.getItem('social_links')).quora:""} target="_blank"><FontAwesomeIcon icon={faQuora} title="Quora"/></a>

                </Col>
                <Col sm={3} className="text-align-right">
                    <p className="small-text">Copyright 2020</p>
                    <p className="small-text">Legal Talent Management Private Limited</p>
                </Col>
            </Row>
        </Container>
        </div>
    )
}

export default Footer;