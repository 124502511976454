import { useState } from "react";

export const useNotification = message => {
   const [showNotification, setshowNotification] = useState(false)
   const [notificationMessage, setMessage] = useState(message)
   const [isError,setIsError] = useState(false)


   return {
      showNotification: (_message = message,isError=false) => {
         setIsError(isError)
         setshowNotification(true);
         setMessage(_message)
         setTimeout(() => {
            setshowNotification(false)
         }, 3000)
      },
      hideNotifcation: () => setshowNotification(false),
      notificationProps: {
         showNotification,
         notificationMessage,
         isError
      }
   }
}