import React from 'react'
import styles from './CPDescriptionVideoDetails.module.css'
import SampleVideoMetaData from './SampleVideoMetaData'
import { Container, Row, Col } from 'react-bootstrap'
import YoutubeWrapper from '../../YoutubeWrapper/YoutubeWrapper';

const CPDescriptionVideoDetails = (props) => {

  const youtubeOpts = {
    height: '263',
    width: '467',
  };

  const youtubePosition = {
    marginBottom: 15,
    paddingLeft: 17,
    paddingRight: 0
  }


  return (
    <div>
      <Container>
        <Row className="justify-content-md-center">
          <Col sm={7}>
            <p className={`primary-color bold uppercase ${styles.title}`}>Watch Sample Video</p>
          </Col>
          <Col sm={2} >
          </Col>
          <Col sm={2}>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={11}>
            <div className={styles.descriptionMargin}>
              <YoutubeWrapper
                opts={youtubeOpts}
                videoId={props.videoId}
                youtubePosition={youtubePosition}
              />
            </div>
          </Col>
        </Row>

        <Row className={styles.rowCorrection}>
        <Col sm={{span:11,offset:1}}>
          <div>
            <p className={`primary-color bold uppercase ${styles.title}`}>Duration</p>
            <p className={styles.titleDescription}>{props.data.duration}</p>
            <div>
              <p className={`primary-color bold uppercase ${styles.title}`}>TIME COMMITMENT</p>
              <p className={styles.titleDescription}>{props.data.time_commitment}</p>
            </div>
            <div>
              <p className={`primary-color bold uppercase ${styles.title}`}>REQUIREMENTS</p>
              <p className={styles.titleDescription}>{props.data.requirements}</p>
            </div>
          </div>
          </Col>
        </Row>


      </Container>
    </div>
  )
}

export default CPDescriptionVideoDetails;
