import React from 'react'
import FAQs from '../FAQs/FAQs'
import ContactForm from '../ContactForm/ContactForm'
import NewsLetter from '../NewsLetter/NewsLetter'
import Maps from '../Maps/Maps'

function ConnectWithUs() {
    return (
        <>
            <FAQs theme="light"/>
            <ContactForm theme="light"/>
            <Maps />
            <NewsLetter />
        </>
    )
}

export default ConnectWithUs
