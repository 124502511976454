import React,{useState,useEffect} from 'react'
import NewsLetter from '../NewsLetter/NewsLetter'
import { Container, Row, Col } from 'react-bootstrap'
import { getDataFromApi } from '../../utils/restAPIhelper'

function Terms() {

    const [terms, setTerms] = useState("Loading...")

    useEffect(()=>{
        getDataFromApi('footer').then(data=>{
            setTerms(data.terms)
        })

    },[])

    return (
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={11}>
                        <p style={{ paddingTop: "160px" }} class="primary-color bold uppercase">{"TERMS & CONDITIONS"}</p>
                        <div className="no-discs" dangerouslySetInnerHTML={{__html:terms}}>
                        </div>
                    </Col>
                </Row>
            </Container>

            <NewsLetter />
        </>
    )
}

export default Terms
