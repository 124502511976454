import React, { useState, useEffect } from 'react'
import styles from './CourseMaterial.module.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import CourseDoneSwitch from './CourseDoneSwitch'
import { getVideoIdFromUrl } from '../../../utils/utils'
import { useIndexedDB } from 'react-indexed-db'
import { DBOBJECT_COURSE_STATUS } from '../../../utils/config'

var statuses = {
  IN_PROGRESS : "In Progress",
  COMPLETED: "Completed",
  PENDING: "Pending"
}


const CourseMaterialRecord = (props) => {

  const db = useIndexedDB(DBOBJECT_COURSE_STATUS);

  const updateStatusinDb = (status) =>{
    db.getByIndex("url",props.record.url).then(courseMaterial=>{
      if (!!courseMaterial){
        db.update({id:courseMaterial.id,url:courseMaterial.url,status:status})
      }
      else{
        db.add({url:props.record.url,status:status})
      }
    })
  }

  useEffect(()=>{
    db.getByIndex("url",props.record.url).then(courseMaterial=>{
      setStatus(courseMaterial?courseMaterial.status:statuses.PENDING)
    })
  },[])

  function CompletedStatusChanged(isChecked) {
   if(isChecked){
    setStatus(statuses.COMPLETED)
    updateStatusinDb(statuses.COMPLETED)
   }
   else{
    setStatus(statuses.IN_PROGRESS)
    updateStatusinDb(statuses.IN_PROGRESS)
   } 
  }

  const setStatusToInProgress = () =>{
    if (status !== statuses.COMPLETED)
    {
    setStatus(statuses.IN_PROGRESS)
    updateStatusinDb(statuses.IN_PROGRESS)
    }
  }

  const [status, setStatus] = useState(props.record.status ? props.record.status : "Pending");

  let thumbnailUrl = require('../../../assets/downloadpdf.png');

  if (props.record.category == "video") {
    thumbnailUrl = "https://img.youtube.com/vi/" + getVideoIdFromUrl(props.record.url) + "/default.jpg";
  }

  return (
    <div className="course-material-record" onClick={setStatusToInProgress}>
      <Container fluid={true} className={`justify-content-md-center ${styles.containerCorrection}`}>
        <Row className={styles.rowMarginTop}>
          <Col sm={3}>
            <img className={styles.recordImage} src={thumbnailUrl} />
          </Col>
          <Col sm={9}>
            <Container fluid={true} className={styles.containerCorrectionListText}>
              <Row className={styles.rowCorrection}> {props.index}. {props.record.title}</Row>
              <Row className={`align-bottom ${styles.rowCorrection}`}>
                <Col className={`uppercase ${styles.recordStatus} `} sm={9} >
                  <p className={status.replace(" ","")}>{status}</p>
                </Col>
                <Col sm={3}>
                  <CourseDoneSwitch statusChanged={CompletedStatusChanged} status={status} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CourseMaterialRecord;
