import React, { useState } from 'react'

import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from "react-google-maps"


const VahuraGoogleMap = withScriptjs(withGoogleMap((props) => {
    const [isOpen, setIsOpen] = useState(true);
    const onToggleOpen = () => {
        setIsOpen(isopen => !isopen)
    }


    return (
        <GoogleMap
            defaultZoom={18}
            defaultCenter={{ lat: 28.528205, lng: 77.215850 }}
        >
            <Marker position={{ lat: 28.528205, lng: 77.215850 }} onClick={onToggleOpen}>
                {isOpen && <InfoWindow
                    position={{ lat: 28.528205, lng: 77.215850 }}
                    onCloseClick={onToggleOpen}
                >
                    <div style={{textAlign:"center"}}>
                        <strong>Delhi</strong><br />
                403, 4th Floor,<br />
                DLF, Courtyard Saket,<br />
                New Delhi - 110017
                <br /><br />
                Phone : +91-11-4900-5600
                <br />
                Email : <a href="mailto:navigator@vahura.com">navigator@vahura.com</a>
                    </div>
                </InfoWindow>}
            </Marker>
        </GoogleMap>)


}))

function Maps() {
    return (
        <div>
            <VahuraGoogleMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyApu9g2di8lzW_BUPdPztDWLJZkIHL0jIs&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />} />
        </div>)
}

export default Maps

