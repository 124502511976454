import React, { useEffect, useState } from 'react'
import NewsLetter from '../NewsLetter/NewsLetter'
import { Container, Row, Col } from 'react-bootstrap'
import { getDataFromApi } from '../../utils/restAPIhelper'

function Privacy() {

    const [privacyPolicy, setprivacyPolicy] = useState("Loading...")

    useEffect(()=>{
        getDataFromApi('footer').then(data=>{
            setprivacyPolicy(data.privacy_policy)
        })

    },[])


    return (
        <>

            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={11}>
                        <p style={{ paddingTop: "160px" }} class="primary-color bold uppercase">{"Privacy Policy"}</p>
                        <div className="no-discs" dangerouslySetInnerHTML={{__html:privacyPolicy}}>

                        </div>
                    </Col>
                </Row>
            </Container>

            <NewsLetter />
        </>
    )
}

export default Privacy
