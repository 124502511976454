import { baseUrl } from "./config"

export const getDataFromApi = (endpoint) => {
    return fetch(`${baseUrl}/${endpoint}`).then(res => {
        if (!res.ok)
            return Promise.reject(res)
        else
            return res.json()
    }).catch(errorResponse => {
        return errorResponse.json().then(errorResponseBody => {
            if (errorResponse.status === 404)
            {
                window.location.href = '/#/not-found-error'
            }
            return Promise.reject({ errorResponse: errorResponseBody, status: errorResponse.status })
        })

    })
}

export const sendDataToApi = (endpoint, body = {}) => {
    return fetch(`${baseUrl}/${endpoint}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" }
    }).then(res => {
        if (!res.ok)
            return Promise.reject(res)
        else
            return res.json()
    }).catch(errorResponse => {
        return errorResponse.json().then(errorResponseBody => {
            return Promise.reject({ errorResponse: errorResponseBody, status: errorResponse.status })
        })

    })
}