import React,{useEffect,useState} from 'react'
import styles from './FreeCourses.module.css'
import Slider from 'react-slick'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { getDataFromApi } from '../../../utils/restAPIhelper'
import { useHistory } from 'react-router-dom'
import NextArrow from '../../Carousel/NextArrow'
import PrevArrow from '../../Carousel/PrevArrow'

const FreeCourses = (props) =>{

    const [courses,setCourses] = useState([])
    const history = useHistory();
    
    useEffect(() => {
        getDataFromApi("courses").then(courses=>{
            courses.sort((a, b) => {
                if (!!a.display_order && !!b.display_order)
                    return a.display_order - b.display_order
                return -1 })
            setCourses(courses)
        })
    }, [])

    const goToCourse = (event, url) => {
        history.push(`/courses/${url}`)
    }

    const renderCourses = (courses) => {
        var settings = {
            dots: false,
            infinite: courses.length >= 4,
            slidesToShow:4,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };
    
        return (<Slider {...settings}>
            {courses.map(course => {
                return (
                    <div className={styles.courseItem} onClick={(e)=>goToCourse(e,course.seo_url)}>
                        <img src={course.thumbnail?course.thumbnail:require('../../../assets/default-navigator.jpg')} />
                        <p>{course.title}</p>
                    </div>
                )
            })}
        </Slider>
        )
    
    }

    return (
        <div className={styles.freeCourseSection}>
        <Container>
            <Row className="justify-content-md-left">
                <Col sm={{ span: 7 }} >
                    <p className={`bold primary-color uppercase ${styles.freeCourseLabel}`}>More Free courses</p>
                </Col>
                <Col sm={2}>
                </Col>
                <Col sm={2}>
                </Col>
            </Row>
            <Row className="justify-content-md-left">
                <Col sm={{span: 12}} className={`${styles.carousel}`}>
                    {renderCourses(courses)}
                </Col>
                <Col sm={1} >
                </Col>
                <Col sm={2}>
                </Col>
            </Row>
        </Container>
    </div>

    )
}




export default FreeCourses
