import React from 'react'
import styles from './ContactForm.module.css'
import { Container, Col, Row, Button } from 'react-bootstrap'
import { useInput } from '../../hooks/input-hook';
import { sendDataToApi } from '../../utils/restAPIhelper';
import { useNotification } from '../../hooks/notification-hook';
import Notification from '../Notifications/Notification'
import { FORM_SUCCESS } from '../../utils/successMessages';

const ContactForm = ({theme}) =>{

    const { value: query, bind: bindQuery, reset: resetQuery } = useInput('');
    const { value: contact_details, bind: bindContactDetails, reset: resetContactDetails } = useInput('');

    const {notificationProps,showNotification,hideNotifcation} = useNotification();

   const onSubmit = () =>{
        var dataToSend = {
            query : query,
            contact_details : contact_details,
           
        }

        sendDataToApi("connect/query",dataToSend).then(data=>{
            showNotification(FORM_SUCCESS)
            resetQuery();
            resetContactDetails();
        }).catch(error=>{
            if (error.status === 400)
            showNotification(error.errorResponse,true)
        })
    }


    return (
        <div className={theme==="light"?styles.containerLight:styles.containerDark}>
              <Notification {...notificationProps}/>
            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={4}>
                        <h2>Did you not find what you were looking for?</h2>
                    </Col>
                    <Col sm={5}>
                        <textarea className={styles.textarea} rows="6" placeholder="Let us know your query here and we'll contact you shortly…" {...bindQuery}/>
                    </Col>
                    <Col sm={2}>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                <Col sm={4}></Col>
                <Col sm={5}>
                <input className={styles.fulltabInput} type="text" name="name_student" placeholder="Mobile No. OR Email ID" {...bindContactDetails}/>
                </Col>
                <Col sm={2}>
                    <Button variant={theme==="light"?"outline-light":"outline-dark"} onClick={e => { onSubmit()}}>Submit</Button>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default ContactForm