import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import styles from './LoginAndSignup.module.css'
import { useInput } from '../../hooks/input-hook';
import { useNotification } from '../../hooks/notification-hook';
import Notification from '../Notifications/Notification';
import { sendDataToApi } from '../../utils/restAPIhelper';
import { useIndexedDB } from 'react-indexed-db';
import { DBOBJECT_USERS } from '../../utils/config';

import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'

const Login = React.memo((props) => {

    const db = useIndexedDB(DBOBJECT_USERS)

    const [showPassword,setShowPassword] = useState(false)

    const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
    const { value: password, bind: bindPassword, reset: resetPassword } = useInput('');

    const { notificationProps, showNotification } = useNotification();

    const [show, setShow] = useState(props.display);

    const handleClose = () => { props.onClose() }

    const Login = () => {
        if (!!email && !!password) {
            sendDataToApi("login", { email: email, password: password }).then(user => {
                if (!!user) {
                    db.clear();
                    db.add({ email: email, isRegistered: true })
                    resetEmail()
                    resetPassword()
                    localStorage.setItem("user_access_token", user.token)
                    handleClose()
                    window.location.reload()
                }
            }).catch(error => {
                showNotification(error.errorResponse, true)
            })
        }
        else
            showNotification("Please fill all the fields in the form", true)

    }
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            Login()
        }
    }

    useEffect(() => {
        setShow(props.display)
    }, [props.display])

    const renderInputAdornment = () =>{
        if (showPassword)
        return <VisibilityOffOutlined className={styles.showPassword} onClick={()=>{setShowPassword(!showPassword)}}/>
        else
        return <VisibilityOutlined className={styles.showPassword} onClick={()=>{setShowPassword(!showPassword)}} />
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered dialogClassName={styles.dialog}>
                <Notification {...notificationProps} />
                <Modal.Body className={styles.modalContainer}>
                    <h2>Login</h2>
                    <form>
                        <input type="email" autoComplete="username" className={`input ${styles.modalInput}`} placeholder="Email ID" {...bindEmail} />
                        <div className={styles.passwordContainer}>
                        <input  type={showPassword?"text":"password"} autoComplete="current-password" name="password" className={`input ${styles.modalInput}`} placeholder="Password"{...bindPassword} onKeyDown={(e) => _handleKeyDown(e)} />
                        {renderInputAdornment()}
                        </div>
                        {/* <p className={styles.forgotpassword}>Forgot password</p> */}
                        <Button variant="outline-dark" onClick={Login}>Login</Button>
                        <p className={styles.signup} onClick={props.onSignUpClicked}>Don't have an account? <span>Sign up</span></p>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default Login
