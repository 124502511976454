import styles from './notification.module.css'
import React from 'react'

const Notification = ({ showNotification, notificationMessage,isError }) => {
    return (
    <div className={`${styles.notification} ${showNotification ? styles.active : styles.hidden} ${isError ? styles.isError : "np"} `} >
        {notificationMessage}
    </div>)
}

export default Notification