import React, { useEffect } from 'react'
import styles from './Header.module.css'
import Menu from '../Menu/Menu'
import { getDataFromApi } from '../../utils/restAPIhelper'
import { Link } from 'react-router-dom'

const Header = (props)=>{
   
useEffect(()=>{
    getDataFromApi("social_links").then(data=>{
        sessionStorage.setItem('social_links',JSON.stringify(data[0]))
    })
},[])

    return (
        <div className={`${styles.header} primary-background`}>
           <Link to="/"><img className={styles.headerLogo} src={require('../../assets/vahura-navigator-logo.png')} alt="Navigator Logo" /></Link>
            <Menu />
        </div>
    )
    
}



export default Header