import React, { useState, useEffect } from 'react'
import styles from './CoursesAndVideosListing.module.css'
import ImageBanner from '../../ImageBanner/ImageBanner'
import { Container, Row, Col, Button } from 'react-bootstrap'
import NewsLetter from '../../NewsLetter/NewsLetter'
import { useHistory } from 'react-router-dom'
import { getDataFromApi } from '../../../utils/restAPIhelper'


const CourseListing = () => {

    const history = useHistory();
    const goToCourse = (event, url) => {
        history.push(`/courses/${url}`)
    }

    const [courses,setCourses] = useState([])
    
    useEffect(() => {
        getDataFromApi("courses").then(courses=>{
             courses.sort((a, b) => {
                if (!!a.display_order && !!b.display_order)
                    return a.display_order - b.display_order
                return -1 })
            setCourses(courses)
        })
    }, [])

    const renderListing = () => {
        return courses.map(course => {
            return (
                <Col md={3}>
                    <div className={styles.courseItem}>
                        <img src={course.thumbnail? course.thumbnail: require('../../../assets/default-navigator.jpg')} />
                        <p className="secondary-color bold"> {course.title}</p>
                        <Button variant="outline-dark" onClick={(e) => goToCourse(e, course.seo_url)}>Details</Button>
                    </div>
                </Col>
            )
        })
    }

    return (
        <>
            <Container className={styles.container}>
                <Row className="justify-content-md-center">
                    <Col sm={11}>
                        <ImageBanner image="https://navigator.vahura.com/api/uploads/5e2661a1779e037690fb78c1-1590642873036.jpg" title="ONLINE COURSES" subtitle="Guidance to take your career that extra mile!" />
                        <p className={`primary-color bold uppercase ${styles.listingTitle}`}>Free Courses</p>
                        <Container >
                            <Row>
                                {renderListing()}
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <NewsLetter />
        </>
    )

}

export default CourseListing
