import React from 'react'
import styles from './ConnectWithUs.module.css'
import { Container, Row, Col, Tabs, Tab, Button } from 'react-bootstrap'
import { useInput } from '../../../hooks/input-hook';
import { sendDataToApi } from '../../../utils/restAPIhelper';
import { useNotification } from '../../../hooks/notification-hook';
import Notification from '../../Notifications/Notification'
import { FORM_SUCCESS } from '../../../utils/successMessages';

const ConnectWithUs = ({data}) => {

    const { value: name, bind: bindName, reset: resetName } = useInput('');
    const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
    const { value: phoneNumber, bind: bindPhoneNumber, reset: resetPhoneNumber } = useInput('');
    const { value: collegeName, bind: bindCollegeName, reset: resetCollegeName } = useInput('');
    const { value: city, bind: bindCity, reset: resetCity } = useInput('');
    const { value: query, bind: bindQuery, reset: resetQuery } = useInput('');
    

    const { value: nameofStudent, bind: bindNameofStudent, reset: resetNameofStudent } = useInput('');
    const { value: emailofStudent, bind: bindEmailofStudent, reset: resetEmailofStudent } = useInput('');
    const { value: phoneNumberofStudent, bind: bindPhoneNumberofStudent, reset: resetPhoneNumberofStudent } = useInput('');

    const {notificationProps,showNotification,hideNotifcation} = useNotification();
    
    const onSubmit = (tab_name) => {
        if (tab_name === "LAW_STUDENT")
        {
            var dataToSend = {
                category : "LAW_STUDENT",
                name:nameofStudent,
                email : emailofStudent,
                phone : phoneNumberofStudent
            }

            sendDataToApi("connect/law_student",dataToSend).then(data=>{
                showNotification(FORM_SUCCESS)
                resetStudentsForm();
            }).catch(error=>{
                if (error.status === 400)
                showNotification(error.errorResponse,true)
            })
        }

         if (tab_name === "LAW_SCHOOL")
        {
            var dataToSend = {
                category : "LAW_SCHOOL",
                name: name,
                email : email,
                phone : phoneNumber,
                college_name : collegeName,
                city : city,
                query:query
            }

            sendDataToApi("connect/law_school",dataToSend).then(data=>{
                showNotification(FORM_SUCCESS)
                resetSchoolForm()
            }).catch(error=>{
                if (error.status === 400)
                showNotification(error.errorResponse,true)
            })
          
        }
        
    }

    const resetSchoolForm = () => {
        resetName()
        resetEmail()
        resetPhoneNumber()
        resetCollegeName()
        resetCity()
        resetQuery()
    }

    const resetStudentsForm = () =>{
        resetNameofStudent()
        resetEmailofStudent()
        resetPhoneNumberofStudent()
    }

    return (
        <div className={styles.connectwithusContainer}>
            <Notification {...notificationProps}/>
            <Container>
                <Row className="justify-content-md-center">
                    <Col sm={4}>
                        <p className="primary-color bold uppercase">Connect with us </p>
                        <h2>{data.title}</h2>
                    </Col>
                    <Col sm={5} className="z-index-1 connect-with-us-tabs">
                        <div className={`primary-background ${styles.connectWithUsForm}`}>
                            <Tabs>
                                {/* <Tab eventKey="student" title="Law Student">
                                    <input className={styles.fulltabInput} type="text" name="name_student" placeholder="Name" {...bindNameofStudent} />
                                    <input className={styles.fulltabInput} type="text" name="email_student" placeholder="Email" {...bindEmailofStudent} />
                                    <input className={styles.fulltabInput} type="number" name="phone_number_student" placeholder="Phone no." {...bindPhoneNumberofStudent} />
                                    <Button variant="outline-secondary" className={`margin-top-25 ${styles.btn}`} onClick={e => { onSubmit('LAW_STUDENT') }} >Submit</Button>
                                </Tab> */}
                                <Tab eventKey="school" title="Law School">
                                    <input className={styles.halftabInput} type="text" name="name_school" placeholder="Name" {...bindName} />
                                    <input className={styles.halftabInput} type="text" name="email_school" placeholder="Email" {...bindEmail} />
                                    <input className={styles.halftabInput} type="number" name="phone_number" placeholder="Phone Number" {...bindPhoneNumber} />
                                    <input className={styles.halftabInput} type="text" name="college_name" placeholder="College Name"{...bindCollegeName} />
                                    <input className={styles.fulltabInput} type="text" name="city" placeholder="City" {...bindCity} />
                                    <textarea className={styles.fulltabInput} type="text" name="query" placeholder="Query" {...bindQuery} />
                                    <Button variant="outline-secondary" className={`margin-top-25 ${styles.btn}`} onClick={e => { onSubmit('LAW_SCHOOL') }} >Submit</Button>
                                </Tab>
                            </Tabs>

                        </div>
                    </Col>
                    <Col sm={2}>
                    </Col>
                </Row>
            </Container>
            <div className={styles.connectwithusContainerimage}>
                <img src={data.image} />
            </div>
        </div>
    )

}

export default ConnectWithUs