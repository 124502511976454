import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import styles from './LoginAndSignup.module.css'
import { useInput } from '../../hooks/input-hook';
import { useNotification } from '../../hooks/notification-hook';
import Notification from '../Notifications/Notification';
import { sendDataToApi } from '../../utils/restAPIhelper';
import { useIndexedDB } from 'react-indexed-db';
import { DBOBJECT_USERS } from '../../utils/config';

import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'
import { Select, MenuItem } from '@material-ui/core';

const Signup = React.memo((props) => {

    const db = useIndexedDB(DBOBJECT_USERS)

    const [showPassword, setShowPassword] = useState(false)

    const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
    const { value: password, bind: bindPassword, reset: resetPassword } = useInput('');
    const { value: name, bind: bindName, reset: resetName } = useInput('');
    const { value: phone_number, bind: bindPhoneNumber, reset: resetPhoneNumber } = useInput('');

    const { notificationProps, showNotification } = useNotification();

    const [show, setShow] = useState(props.display);
    const [userCategory, setUserCategory] = useState(props.display);

    const handleClose = () => { props.onClose() }

    const handleChange = event =>{
        setUserCategory(event.target.value)
    }

    const SignUp = () => {
        if (!!email && !!password && !!userCategory && !!name && !!phone_number) {
            sendDataToApi("register", { name: name, email: email, password: password, phone_number: phone_number,user_category:userCategory }).then(user => {
                if (!!user) {
                    db.clear();
                    resetEmail()
                    resetPassword()
                    resetName()
                    resetPhoneNumber()
                    localStorage.setItem("user_access_token", user.token)
                    db.add({ email: email, phone_number: phone_number, isRegistered: true })
                    handleClose()
                    window.location.reload()
                }
            }).catch(error => {
                showNotification(error.errorResponse, true)
            })
        }
        else
            showNotification("Please fill all the fields in the form", true)

    }
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            SignUp()
        }
    }

    useEffect(() => {
        setShow(props.display)
    }, [props.display])


    const renderInputAdornment = () => {
        if (showPassword)
            return <VisibilityOffOutlined className={styles.showPassword} onClick={() => { setShowPassword(!showPassword) }} />
        else
            return <VisibilityOutlined className={styles.showPassword} onClick={() => { setShowPassword(!showPassword) }} />
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered dialogClassName={styles.dialog}>
                <Notification {...notificationProps} />
                <Modal.Body className={styles.modalContainer}>
                    <h2>Signup with us!</h2>
                    <p className={styles.subtitle}>PLEASE ADD YOUR DETAILS BELOW</p>
                    <form>
                        <input type="name" autoComplete="name" className={`input ${styles.modalInput}`} placeholder="Name" {...bindName} />
                        <input type="email" autoComplete="username" className={`input ${styles.modalInput}`} placeholder="Email ID" {...bindEmail} />
                        <div className={styles.passwordContainer}>
                            <input type={showPassword ? "text" : "password"} autoComplete="new-password" name="password" className={`input ${styles.modalInput}`} placeholder="Password"{...bindPassword} />
                            {renderInputAdornment()}
                        </div>
                        <input type="number" autoComplete="phone-number" className={`input ${styles.modalInput}`} placeholder="Phone Number" {...bindPhoneNumber} onKeyDown={(e) => _handleKeyDown(e)} />
                        <Select
                            className={`input ${styles.modalInput} ${styles.select}`}
                            disableUnderline
                            id="demo-simple-select"
                            value={userCategory}
                            onChange={handleChange}
                        >
                            <MenuItem value={"student"}>I am a law student</MenuItem>
                            <MenuItem value={"lawyer"}>I am a lawyer</MenuItem>
                        </Select>



                        <Button variant="outline-dark" onClick={SignUp}>Sign up</Button>
                        <p className={styles.signup} onClick={props.onLoginClicked}>Already have an account? <span>Login</span></p>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default Signup
