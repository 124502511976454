import React from 'react'
import styles from './CourseMaterial.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import YoutubeWrapper from '../../YoutubeWrapper/YoutubeWrapper';
import PdfViewer from '../../PdfViewer/PdfViewer';
import { getVideoIdFromUrl } from '../../../utils/utils';

const youtubeOpts = {
      height: '583',
      width: '1024',
      playerVars: {
        autoplay: 0,
        rel: 0
      }
};

const youtubePosition = {
  marginBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: "97px",
  width: "100%"
}


const CourseMaterialViewPane = (props) =>{
  
          if (props.courseRecord.category == "video"){
            return (
                <div>
                  <YoutubeWrapper opts={youtubeOpts}
                                  videoId={getVideoIdFromUrl(props.courseRecord.url)}
                                  youtubePosition={youtubePosition}
                  />
                </div>
            )
          }
          else if (props.courseRecord.category == "document"){
            return (
                <div>
                  <PdfViewer url={props.courseRecord.url}/>
                </div>
            )
          }
          else 
          return <div>Loading...</div>
}

export default CourseMaterialViewPane;
